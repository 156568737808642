import React, { Fragment } from "react"
import { useStaticQuery, graphql } from "gatsby"
import Navbar from "./navbar"
import Footer from "./footer"
import SEO from './seo'
import PageTransition from 'gatsby-plugin-page-transitions'
import Cookies from 'js-cookie';

export default ({ pageTitle, pageDescription, pageImage, pathname, pageArticle, bodyBgColor, bodyTextColor, bodyTitleColor, children }) => {

  const { contentfulPrimaryNavigation } = useStaticQuery(graphql`
      query LayoutQuery {
        contentfulPrimaryNavigation(title: {eq: "Primary"}) {
          pages {
            ... on ContentfulStaticPage {
              slug
              title
            }
            ... on ContentfulHomePage {
              slug
              title
            }
            ... on ContentfulModularPage {
              slug
              title
            }
          }
        }
      }
    `)

  // if (! Cookies.get('is_allowed')) {
  //   return <h1>Not allowed!</h1>
  // }

  return (
    <Fragment>
      <SEO
        title={pageTitle}
        description={pageDescription}
        pathname={pathname}
        image={pageImage}
        article={pageArticle}
        bodyBgColor={bodyBgColor}
        bodyTextColor={bodyTextColor}
        bodyTitleColor={bodyTitleColor}
      />
      <Navbar pages={contentfulPrimaryNavigation.pages} textColor={bodyTextColor} />
      <PageTransition>
        {/*https://codepen.io/chriswrightdesign/pen/emQNGZ*/}
        <div className="flex">
          <div className="w-full flex flex-col min-h-screen">
            {children}
            <Footer textColor={bodyTextColor} />
          </div>
        </div>
      </PageTransition>
    </Fragment>
  )
}
