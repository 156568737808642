import React, { Fragment } from "react"
import { Link } from "gatsby"
import Headroom from "react-headroom"
import Logo from "./logo"
import styles from "../css/modules/navbar.module.css"

export default class Navbar extends React.Component {
  constructor(props) {
    super(props)
    this.state = { showMenu: false };

    this.toggleMenu = this.toggleMenu.bind(this);
  }

  onScrollHandler(e) {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
      return false;
    }
  }

  fixOnScroll() {
    window.addEventListener('scroll', this.onScrollHandler, { passive: false });
    window.addEventListener('mousewheel', this.onScrollHandler, { passive: false });
    window.addEventListener('touchmove', this.onScrollHandler, { passive: false });
  }

  unfixOnScroll() {
    window.removeEventListener('scroll', this.onScrollHandler);
    window.removeEventListener('mousewheel', this.onScrollHandler);
    window.removeEventListener('touchmove', this.onScrollHandler);
  }

  toggleMenu() {
    this.setState({ showMenu: ! this.state.showMenu });

    let self = this;
    setTimeout(function () {
      self.state.showMenu ? self.fixOnScroll() : self.unfixOnScroll()
    }, 50)
  }

  render() {
    return (
      <Fragment>
        <Headroom disableInlineStyles={true}>
          <div
            className="h-20 md:h-26 flex flex-wrap items-center"
            style={{
              color: this.props.textColor || '#000'
            }}
          >
            <div className="container flex justify-between items-center">
              <Logo textColor={this.props.textColor} />

              <button onClick={this.toggleMenu} className="flex-none relative z-50 focus:outline-none">
                <svg className="w-10 h-10 fill-current" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><g transform="translate(0 -7)"><path d="m24 7v1.5h-24v-1.5z" transform="translate(0 7.5)"/><path d="m24 22v1.5h-24v-1.5z"/></g></svg>
              </button>
            </div>
          </div>
        </Headroom>

        <div className={`${styles.menu} ${this.state.showMenu ? styles.menuOpen : ''}`}>
            <div className="h-20 md:h-26 container flex items-center justify-end">
              <button onClick={this.toggleMenu} className="relative focus:outline-none" style={{zIndex: 9999}}>
                <svg className="w-10 h-10 fill-current text-white" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><g transform="matrix(.70710678 .70710678 -.70710678 .70710678 9.21967 -8.500893)"><path d="m28.6 11.6v1.5h-24v-1.5z"/><path d="m4.6 11.6h24v1.5h-24z" transform="matrix(.00000254 1 -1 .00000254 28.9926 -4.2856)"/></g></svg>
              </button>
            </div>

            <div className="absolute inset-0 flex items-center">
              <nav className="container flex flex-col items-start">
              {this.props.pages.map((link, index) => (
                <Link key={index} to={`${link.slug === 'home' ? '/' : `/${link.slug}/`}`} className={styles.navbarLink} onClick={this.toggleMenu}>
                  {link.title}
                </Link>
              ))}
              </nav>
            </div>
        </div>
      </Fragment>
    )
  }
}
