import React, { useState, useEffect} from "react"
import { useStaticQuery, graphql } from "gatsby"

export default () => {

    const cookies = ['gatsby-gdpr-google-analytics', 'gatsby-gdpr-google-tagmanager', 'gatsby-gdpr-facebook-pixel']

    const data = useStaticQuery(graphql`
        query CookieQuery {
            contentfulCookieConsent {
                id
                title
                acceptButton
                denyButton
                description {
                    childMarkdownRemark {
                        html
                    }
                }
            }
        }
    `)
    const {title, acceptButton, denyButton, description} = data.contentfulCookieConsent
    const [doesNotHaveCookies, setDoesNotHaveCookies] = useState(true)

    const approove = (setting) => {

        cookies.forEach((cookie)=>{
            document.cookie = `${cookie}=${setting}`;
        })
        setDoesNotHaveCookies(false)
    }

    useEffect(()=>{
        if (typeof window !== `undefined`) {
            setDoesNotHaveCookies(!document.cookie.split('; ').find(row => row.startsWith('gatsby-gdpr-google-tagmanager')))
        }
    },[])
    return (
        <>
            {doesNotHaveCookies &&
                <div className="inset-x-0 bottom-0 fixed p-4 bg-white text-black">
                    <div className="container">

                        <h4 className="text-black">{title}</h4>
                        <div className="text-black" dangerouslySetInnerHTML={{'__html': description.childMarkdownRemark.html} }></div>

                        <button
                            className="bg-black py-2 px-4 mt-2 text-white mr-4"
                            onClick={(e)=> { approove('true') } }
                        >{acceptButton}</button>

                        <button
                            className="underline mt-2 text-sm"
                            onClick={(e)=> { approove('false') } }
                        >{denyButton}</button>

                    </div>
                </div>
            }
        </>
    )
}