import React from 'react'
import { Link } from "gatsby"

export default ({textColor}) => (
  <Link to="/" exit={{length: 0.1}} entry={{length: 0.3, delay: 0.15}}>
    <svg className="w-auto h-6 md:w-auto md:h-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 861.757 84.168">
      <g>
        <path d="M20.236,50.667H38.043c4.145-.009,6.923.788,8.5,1.91a5.221,5.221,0,0,1,2.244,4.642v.219A5.292,5.292,0,0,1,46.4,62.251c-1.6,1.084-4.264,1.741-7.808,1.732H20.236ZM44.477,26.526A5,5,0,0,1,42.034,31.2c-1.651,1.048-4.405,1.662-7.967,1.657H20.233V20.2H35.171c3.353,0,5.762.693,7.2,1.728a4.962,4.962,0,0,1,2.108,4.379ZM56.988,39.989a19.347,19.347,0,0,0,8.061-16.327v-.221a19.725,19.725,0,0,0-7.53-15.987C52.7,3.648,45.9,1.621,37.71,1.621H0v80.94H38.6c8.958,0,16.549-1.792,22.051-5.626,5.52-3.819,8.742-9.841,8.706-17.4v-.221c.1-9.764-4.884-15.715-12.364-19.323" fill="#ee3423"/>
        <path d="M116.115,57.219v.219a5.279,5.279,0,0,1-2.385,4.813c-1.595,1.084-4.26,1.741-7.806,1.732H87.565V50.667h17.807c4.146-.009,6.926.788,8.5,1.91a5.221,5.221,0,0,1,2.244,4.642m-4.3-30.693a5,5,0,0,1-2.445,4.678c-1.653,1.048-4.405,1.662-7.96,1.653H87.565V20.2h14.941c3.343,0,5.762.693,7.188,1.728a4.976,4.976,0,0,1,2.116,4.379Zm12.51,13.463a19.332,19.332,0,0,0,8.057-16.327v-.221a19.727,19.727,0,0,0-7.529-15.987c-4.826-3.806-11.619-5.833-19.809-5.833H67.329v80.94h38.6c8.961,0,16.552-1.792,22.054-5.626,5.515-3.819,8.741-9.841,8.7-17.4v-.221c.1-9.764-4.884-15.715-12.361-19.323" fill="#ee3423"/>
        <path d="M188.079,42.094v.219c-.1,12.794-8.409,21.124-21.448,21.227H155.337V20.645h11.294c13.012.095,21.352,8.641,21.448,21.449m9.453-28.913c-7.587-7.237-18.342-11.565-30.9-11.558H134.657V82.567h31.991c12.563,0,23.308-4.393,30.894-11.681a39.418,39.418,0,0,0,11.99-28.792v-.222a39.085,39.085,0,0,0-12-28.691" fill="#ee3423"/>
        <path d="M227.278,41.876c.063-12.376,8.7-22.2,21.344-22.228,12.62.017,21.515,10.09,21.557,22.447v.216c-.053,12.375-8.7,22.2-21.339,22.222-12.62-.015-21.515-10.088-21.562-22.438ZM279.55,12.458C271.948,4.927,261.223.29,248.84.293A43.27,43.27,0,0,0,218.021,12.57,41.316,41.316,0,0,0,205.832,42.1v.216A41.084,41.084,0,0,0,217.91,71.727c7.6,7.531,18.331,12.165,30.712,12.161h.017a43.285,43.285,0,0,0,30.8-12.275A41.275,41.275,0,0,0,291.628,42.1v-.219A41.092,41.092,0,0,0,279.55,12.458" fill="#ee3423"/>
      </g>
      <g>
        <path d="M307.369,1.619H328.3l33.3,42.777V1.619h22.2V82.55H364.252l-34.685-44.4v44.4h-22.2Z" fill={textColor}/>
        <path d="M396.51,42.315v-.231C396.51,18.846,415.24,0,440.213,0s43.471,18.73,43.471,41.853v.231c0,23.239-18.729,42.084-43.7,42.084S396.51,65.438,396.51,42.315Zm64.168,0v-.231c0-11.792-8.325-21.851-20.7-21.851s-20.58,9.943-20.58,21.62v.231c0,11.562,8.44,21.852,20.811,21.852C452.469,63.936,460.678,53.993,460.678,42.315Z" fill={textColor}/>
        <path d="M496.289,1.619h38.269c12.487,0,20.926,3.237,26.361,8.671,4.74,4.74,7.168,11.1,7.168,18.845v.232c0,12.486-6.475,20.695-16.649,24.972L570.746,82.55H544.964l-16.3-24.511h-9.943V82.55h-22.43Zm37.344,38.847c7.515,0,12.024-3.7,12.024-9.6v-.232c0-6.474-4.74-9.711-12.14-9.711h-14.8V40.466Z" fill={textColor}/>
        <path d="M582.656,1.619h31.332c28.788,0,45.553,16.533,45.553,40v.231c0,23.47-17,40.7-46.015,40.7h-30.87Zm22.43,19.77V62.664h9.134c13.527,0,22.429-7.4,22.429-20.464v-.231c0-13.065-8.9-20.58-22.429-20.58Z" fill={textColor}/>
        <path d="M672.722,1.619h22.43V82.55h-22.43Z" fill={textColor}/>
        <path d="M708.217,42.315v-.231C708.217,18.5,726.253,0,750.763,0,767.18,0,778.049,6.937,785.1,16.764l-16.88,13.065c-4.625-5.665-9.827-9.48-17.689-9.48-11.33,0-19.308,9.711-19.308,21.5v.231c0,12.14,7.978,21.62,19.308,21.62,8.44,0,13.3-3.931,18.268-9.827L785.679,65.9c-7.746,10.521-17.92,18.267-35.725,18.267C726.831,84.168,708.217,66.479,708.217,42.315Z" fill={textColor}/>
        <path d="M791.116,70.525,803.6,55.611c8.208,6.475,17,9.712,26.244,9.712,6.128,0,9.366-1.966,9.366-5.55v-.231c0-3.468-2.659-5.087-13.758-7.862-17.111-3.93-30.524-8.671-30.524-25.32v-.231c0-15.146,11.91-25.9,31.332-25.9,13.875,0,24.511,3.7,33.3,10.752l-11.215,15.84c-7.4-5.434-15.376-7.977-22.661-7.977-5.433,0-8.092,2.081-8.092,5.2v.231c0,3.584,2.774,5.434,14.105,7.862,18.5,4.047,30.06,10.059,30.06,25.205v.231c0,16.532-13.065,26.361-32.719,26.361C814.586,83.938,801.29,79.428,791.116,70.525Z" fill={textColor}/>
      </g>
    </svg>
  </Link>
)
