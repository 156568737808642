import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Logo from "./logo"
import CookieConsent from "./cookieConsent"

export default () => {
  const data = useStaticQuery(graphql`
      query FooterQuery {
        allContentfulOffice {
          edges {
            node {
              title
              addressLine {
                childMarkdownRemark {
                  html
                }
              }
              googleMapsUrl
            }
          }
        }
      }
    `)

  return (
    <footer className="mt-auto">
      <div className="bg-black text-white py-12">
        <div className="container">
          <div className="w-full">
            <Logo textColor="#fff" />
          </div>

          {
            data.allContentfulOffice &&
            <div className="flex flex-wrap -mx-12 pb-12 md:py-12">
              {data.allContentfulOffice.edges.map((office, index) => (
                <div key={index} className="w-full md:w-1/3 px-12 mt-12 md:mt-0">
                  <h3 className="text-white">{office.node.title}</h3>
                  <div dangerouslySetInnerHTML={{ __html: office.node.addressLine.childMarkdownRemark.html }}></div>
                  {office.node.googleMapsUrl && <a href={office.node.googleMapsUrl}>Show on Google Maps</a>}
                </div>
              ))}
            </div>
          }

          <div className="w-full">
            <a href="https://www.linkedin.com/company/bbdonordics" target="_BLANK" rel="noopener noreferrer" className="mr-8">Linkedin</a>
            <a href="https://www.instagram.com/bbdo_nordics/" target="_BLANK" rel="noopener noreferrer">Instagram</a>
          </div>

          <p className="my-12">
            BBDO Nordics is a part of <a href="https://www.omnicomgroup.com">Omnicom Group Inc.</a> &copy; {new Date().getFullYear()} BBDO Nordics. All rights reserved.
          </p>
          <CookieConsent />
        </div>
      </div>
    </footer>
  )
}
